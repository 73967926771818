import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { gamezone_random_games } from '../../controller/actions';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { NewContext } from '../../Context/NewContext';
import AdsComponent from '../../components/AdsComponent/AdsComponent';
import GameCard from '../../components/GameCard/GameCard';
import { FaPlay } from 'react-icons/fa';
import AdModal from '../../components/AdsComponent/AdModal';
// import Data from './../../components/gamesData.json'
import Data from './../../components/gamezop.json'
import { Helmet } from 'react-helmet';
import axios from 'axios';

function GamesPreview({ handleGameClickCount, setIsHeaderFooterShown, gameAdsSettings }) {
    const { isLoading, screenWidth } = useContext(NewContext);
    const navigate = useNavigate();
    // const { state } = useLocation();
    const { name } = useParams();
    // const [gamesList, setGamesList] = useState(Data?.GamesList)
    const [gamesList, setGamesList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [gameId, setGameId] = useState('')
    const [imageBlur, setImageBlur] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [ShowBanner, setShowBanner] = useState(true);
    const [isFullscreen, setIsFullScreen] = useState(false);
    const [continueGame, setContinueGame] = useState(false);
    const [state, setState] = useState([]);

    // useEffect(() => {
    //     const New_Data = Data.filter((item) => item.slug == name);
    //     setState(New_Data[0]);
    // }, [state])



    // useEffect(() => {
    //     if (name === "*") {
    //         setState(Data[0]);
    //     } else {
    //         const New_Data = Data?.GamesList?.filter((item) => item?.Name === name?.replace('-', ' '));
    //         setState(New_Data[0] || null);
    //     }
    // }, [name]);

    const getGameListFunc = async () => {
        const api_res = await axios.get("https://pub.gamezop.com/v3/games?id=9632&lang=en")
        setGamesList(api_res?.data?.games)
        const New_Data = api_res?.data?.games?.filter((item) => item?.name?.en?.toLowerCase().replace(/\s+/g, "-") === name);
        setState(New_Data[0] || null);
    }

    useEffect(() => {
        getGameListFunc();
    }, [name]);




    const isAndroid = navigator.userAgent.toLowerCase().includes('android');
    // const isDesktop = !isIOS && !isAndroid;
    const OpenModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        setIsModalOpen(false);

        if (isAndroid) {
            setTimeout(() => {
                handleFullScreenToggle();
            }, 200);
        }
        if (isFullscreen) {
            setShowBanner(true);
        }
    };

    // const handleFullScreenToggle = () => {
    //     const iframe = document.getElementById('gamePlayerIframe');
    //     if (iframe.requestFullscreen) {
    //         iframe.requestFullscreen();
    //     } else if (iframe.mozRequestFullScreen) {
    //         iframe.mozRequestFullScreen();
    //     } else if (iframe.webkitRequestFullscreen) {
    //         iframe.webkitRequestFullscreen();
    //     } else if (iframe.msRequestFullscreen) {
    //         iframe.msRequestFullscreen();
    //     }
    // };

    const handleFullScreenToggle = () => {
        const element = document.getElementById('gamePlayerIframe');

        if (element) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
            setIsFullScreen(true);
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (
                !document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement
            ) {
                setShowBanner(true)
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, [isFullscreen]);



    // const getGameListFunc = async () => {
    //     const randomGames = await gamezone_random_games(30);
    //     if (randomGames) {
    //         const { status, data, } = randomGames;
    //         if (status) {
    //             setGamesList(data);
    //         } else {
    //             setGamesList([]);
    //         }
    //     }
    // }
    const handleImageLoad = () => {
        setImageLoaded(true);
        setTimeout(() => setImageBlur(false), 800);
    };

    // const handleGame = (link, name, banner) => {
    //     navigate(`/g/${name?.en?.toLowerCase().replace(/ /g, '-')}`, { state: { link, banner, name } });
    // }

    const playNowHandle = (url) => {
        window.open(url)
        // OpenModal();
        // setShowBanner(false);
    }


    useEffect(() => {
        setShowBanner(true);
        if (state?.link) {
            const isIosCheck = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            setIsIOS(isIosCheck)
            if (!isIosCheck) {
                setIsHeaderFooterShown(true);
            } else {
                setIsModalOpen(!isModalOpen);
                playNowHandle()
            }
        } else {
            setIsHeaderFooterShown(true);
        }
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [state, gameId])

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [state])


    const loadAdSlot = (slotId, adUnit, dimensions) => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
        document.body.appendChild(script);

        script.onload = () => {
            window.googletag = window.googletag || { cmd: [] };
            window.googletag.cmd.push(() => {
                window.googletag
                    .defineSlot(adUnit, dimensions, slotId)
                    .addService(window.googletag.pubads());
                window.googletag.enableServices();
                window.googletag.display(slotId);
            });
        };
    };

    useEffect(() => {
        // Load multiple ad slots dynamically
        loadAdSlot('div-gpt-ad-160x600-1', '/21849154601,23199743504/Ad.Plus-160x600', [160, 600]);
        loadAdSlot('160x600-1', '/21849154601,23199743504/Ad.Plus-160x600', [160, 600]);
        loadAdSlot('970x90-1', '/21849154601,23199743504/Ad.Plus-970x90', [970, 90]);
        loadAdSlot('300x600-1', '/21849154601,23199743504/Ad.Plus-300x600', [300, 600]);
    }, []);

    return (
        <>
            {
                state?.url &&

                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="theme-color" content="#F4F4F4" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                    <meta name="apple-mobile-web-app-title" content="TheGameZone.fun" />
                    <meta name="HandheldFriendly" content="true" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta http-equiv="x-ua-compatible" content="ie=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimal-ui, shrink-to-fit=no viewport-fit=cover" />
                    <title>{state?.name?.en} - Play Online for Free! | TheGameZone</title>
                    <meta name="title" content={`${state?.name?.en} - Play Online for Free! | TheGameZone`} />
                    <meta name="description" content={`Play ${state?.name?.en} on the most popular website for free online games! TheGameZone works on your mobile, tablet, or computer. No downloads, no login. Play now!`} />
                    <meta name="keywords" content={`${state?.name?.en} - Play Online for Free! | TheGameZone`} />
                    <meta property="og:title" content={`${state?.name?.en} - Play Online for Free! | TheGameZone`} />
                    <meta property="og:description" content={`Play ${state?.name?.en} on the most popular website for free online games! TheGameZone works on your mobile, tablet, or computer. No downloads, no login. Play now!`} />
                    <meta property="og:url" content="https://thegamezone.fun/" />
                    <meta property="og:site_name" content="TheGameZone" />
                    <meta property="og:image" content="https://thegamezone.fun/assets/images/logo.png" />
                    <meta property="og:image" content="https://thegamezone.fun/assets/images/logo.png" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`${state?.name?.en} - Play Online for Free! | TheGameZone`} />
                    <meta name="twitter:description" content={`Play ${state?.name?.en} on the most popular website for free online games! TheGameZone works on your mobile, tablet, or computer. No downloads, no login. Play now!`} />
                    <meta name="twitter:image" content="https://thegamezone.fun/assets/images/logo.png" />
                    <link rel="canonical" href="https://thegamezone.fun/"></link>
                </Helmet>
            }

            {
                isIOS === true ?
                    <>
                        {
                            isModalOpen ? <OpenModal /> : <><iframe src={state?.url} title={state?.name?.en} id='gamePlayerIframe' allow="autoplay" className='w-screen h-screen' /></>
                        }
                    </> :
                    <>
                        <div className='grid grid-cols-6 md:grid-cols-12  gap-5 mt-2 md:mt-7 mb-5'>
                            <div className='col-span-6 md:col-span-2 hidden md:block'>
                                <div className="grid grid-cols-12 ml-2 md:ml-7 gap-3 md:hidden lg:grid">  {/** Side Ads */}
                                    {
                                        <div className="col-span-12">
                                            <div className="rounded-lg bg-white w-full h-fit text-center pt-1">
                                                <p className='text-[8px] uppercase'>
                                                    Advertisement
                                                </p>
                                                <div>
                                                    <AdsComponent adSize={'200x635'} dataAdSlot="9630371299" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="grid grid-cols-12 gap-3 lg:hidden">
                                    {
                                        gamesList.slice(0, 5).map((item, i) => {
                                            return (
                                                <div key={i} className="col-span-12 w-28">
                                                    <div className={`bg-[#ffff] rounded-[10px] overflow-hidden shadow-md mt-2`}>
                                                        <div className='cursor-pointer' onClick={() => { }}>
                                                            <div className="overflow-hidden w-full">
                                                                {!imageLoaded && (
                                                                    <div
                                                                        className="bg-gray-300 w-full h-full animate-wave-skeleton"
                                                                    ></div>
                                                                )}
                                                                <img
                                                                    src={item?.assets?.square}
                                                                    alt={item?.name?.en}
                                                                    className={`w-full h-full object-cover rounded-[10px] ${!imageLoaded ? 'blur hidden' : 'transition ease-out blur-none duration-700'}`}
                                                                    style={imageBlur ? { filter: 'blur(20px)' } : {}}
                                                                    onLoad={handleImageLoad}
                                                                />
                                                            </div>
                                                            <div className={`p-1 `} >
                                                                <p className="text-[10px] md:text-sm leading-none font-bold text-center truncate">
                                                                    {item?.name?.en}
                                                                </p>
                                                            </div>
                                                        </div >
                                                    </div >
                                                </div >
                                            )
                                        })
                                    }
                                </div >
                            </div>
                            <div className="col-span-6 md:col-span-8 m-2 md:m-0">
                                {
                                    ShowBanner ? <>
                                        <div className="h-[400px] sm:h-full 2xl:h-[655px]">
                                            <div className='h-full bg-black rounded-xl relative'>
                                                <div className='h-full opacity-25'>
                                                    <img src={state?.assets?.square} className='w-full h-full rounded-xl' alt={state?.name?.en} />
                                                </div>
                                                <div className='w-full h-full text-white flex flex-col justify-center items-center absolute top-0 left-0 z-[2]'>

                                                    <div className='text-center '>
                                                        <div className='h-52 mb-3 	 hidden md:block'>
                                                            <img src={state?.assets?.square} className='w-full h-full shadow-2xl border-4 border-white rounded-full' alt={state?.name?.en} />
                                                        </div>
                                                        <div className='my-5 font-bold text-2xl'>{state?.name?.en}</div>
                                                        <div className={`w-[170px] bg-[#5AC4D1] rounded-full flex cursor-pointer mx-auto justify-center gap-2 py-3`} onClick={() => playNowHandle(state?.url)}>
                                                            <b className='text-[#ffff] text-xl'>PLAY GAME</b>
                                                        </div>
                                                    </div>

                                                    <div className="text-center mt-5 ">
                                                        <ins class="adsbygoogle"
                                                            style={{ display: "inline-block", width: "300px", height: "50px" }}
                                                            data-ad-client="ca-pub-8383910619750608"
                                                            data-ad-slot="6473234616"></ins>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                        <>
                                            <div className="w-full h-full">
                                                <div className="flex flex-col w-full h-[400px] sm:h-full bg-black rounded-lg">
                                                    <iframe src={state?.url} title='gamePlayer' id='gamePlayerIframe' allow="autoplay" sandbox="allow-scripts allow-same-origin" className='rounded-md w-full h-[400px] sm:h-full'></iframe>
                                                    <div className="w-full bottom-0 bg-white rounded-b-md p-2 px-0 mt-0 z-40">
                                                        <div className="flex justify-between items-center mr-3">
                                                            <div className='ml-2 flex gap-2'>
                                                                <span> <img src={state?.assets?.square} className='w-10 h-10 rounded-full' alt='game-banner' /></span>
                                                                <span className='mt-2 font-bold'>{state?.name?.en}</span>
                                                            </div>
                                                            <BsArrowsFullscreen className='text-black cursor-pointer mr-3' onClick={handleFullScreenToggle} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }

                            </div>
                            <div className='col-span-6 md:col-span-2 md:block'>
                                <div className='col-span-6 md:col-span-2 '>
                                    <div className="grid grid-cols-12 gap-3  md:hidden lg:grid">
                                        {
                                            <div className="col-span-12 mr-0 md:mr-7">
                                                <div className="rounded-lg bg-white w-full h-fit text-center pt-1">
                                                    <p className='text-[8px] uppercase'>
                                                        Advertisement
                                                    </p>
                                                    <div className='hidden lg:block xl:block'>
                                                        <AdsComponent adSize={'200x635'} dataAdSlot="9630371299" />
                                                    </div>
                                                    <div className='block md:hidden'>
                                                        <AdsComponent adSize={`${screenWidth}x220`} dataAdSlot="9630371299" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="grid-cols-12 gap-3 hidden md:grid lg:hidden">
                                        {
                                            gamesList.slice(11, 16).map((item, i) => {
                                                return (
                                                    <div key={i} className="col-span-12 w-28">
                                                        <div className={`bg-[#ffff] rounded-[10px] overflow-hidden shadow-md mt-2`}>
                                                            <div className='cursor-pointer' onClick={() => { }}>
                                                                <div className="overflow-hidden w-full">
                                                                    {!imageLoaded && (
                                                                        <div
                                                                            className="bg-gray-300 w-full h-full animate-wave-skeleton"
                                                                        ></div>
                                                                    )}
                                                                    <img
                                                                        src={item?.assets?.square}
                                                                        alt={item?.name?.en}
                                                                        className={`w-full h-full object-cover rounded-[10px] ${!imageLoaded ? 'blur hidden' : 'transition ease-out blur-none duration-700'}`}
                                                                        style={imageBlur ? { filter: 'blur(20px)' } : {}}
                                                                        onLoad={handleImageLoad}
                                                                    />
                                                                </div>
                                                                <div className={`p-1 `} >
                                                                    <p className="text-[10px] md:text-sm leading-none font-bold text-center truncate">
                                                                        {item?.name?.en}
                                                                    </p>
                                                                </div>

                                                            </div >
                                                        </div >
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div >
                            </div >
                        </div>
                        {/* <div className="hidden md:block lg:hidden col-span-12 mx-7">
                             Mobile & PC Ads 
                            {gamesList.length > 0 && gameAdsSettings?.gamePreviewSideAd?.isActive && <div className="rounded-lg bg-white w-fit md:w-full h-fit text-center pt-1">
                                <p className='text-[8px] uppercase'>
                                    // Advertisement
                                </p>
                                <div className='block'>
                                    <AdsComponent adSize={'650x220'} dataAdSlot="9630371299" />
                                </div>
                            </div>}
                        </div> */}


                        {/* bottom card */}
                        < div className='container mx-auto mb-5 mt-2 p-3 ' >
                            <div className="hidden md:block rounded-lg bg-white text-center">
                                <p className="text-[8px] uppercase">Advertisement</p>

                                <div className="hidden lg:block">
                                    <AdsComponent adSize="970x280" dataAdSlot="6934628026" />
                                </div>

                                <div className="md:block lg:hidden">
                                    <AdsComponent adSize="650x280" dataAdSlot="6934628026" />
                                </div>
                            </div>

                            <div className="bg-white shadow-lg rounded-lg p-8 my-8 mx-auto">
                                <div className="flex justify-between items-center mb-6">
                                    <h1 className="text-2xl font-bold text-[#5AC4D1]">{state?.name?.en} (<span className="text-sm text-gray-500">{state?.categories?.en?.[0]}</span>)</h1>

                                </div>

                                <p className="text-gray-700 mb-4"><strong>Description:</strong> {state?.description?.en}</p>

                                <div className="mb-4 flex gap-2">
                                    <p className="text-gray-700 font-semibold">Copy Link:</p>
                                    <a
                                        href={state?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline overflow-scroll "
                                        onClick={() => navigator.clipboard.writeText(state?.url)}
                                    >
                                        {state?.url}
                                    </a>
                                </div>

                                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                                    How to Play {state?.name?.en}
                                </h3>

                                <ol className="list-decimal pl-6 space-y-4 text-gray-700">
                                    <li>
                                        <strong>Start the Game:</strong> Click on the “Play” button on the game’s main page to begin.
                                    </li>
                                    <li>
                                        <strong>Understand the Objective:</strong> Each game has a specific goal. For instance, in puzzle games, the objective might be to solve all puzzles, while in action games, it might be to defeat enemies or complete levels.
                                    </li>
                                    <li>
                                        <strong>Learn the Controls:</strong> Familiarize yourself with the game controls. Common controls might include:
                                        <ul className="list-disc pl-6 mt-2 space-y-1">
                                            <li><strong>W, A, S, D</strong> keys for movement</li>
                                            <li><strong>Mouse</strong> for aiming and interacting</li>
                                            <li><strong>Spacebar</strong> for jumping</li>
                                            <li><strong>Enter</strong> or <strong>Return</strong> for confirming actions</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Follow On-Screen Instructions:</strong> Many games provide tutorials or hints. Pay attention to these instructions to learn game-specific mechanics.
                                    </li>
                                    <li>
                                        <strong>Explore the Game:</strong> Take time to explore the game’s environment. Experiment with strategies and find what works best for you.
                                    </li>
                                    <li>
                                        <strong>Check the Settings:</strong> Adjust game settings (e.g., volume, graphics) to your preference for a better experience.
                                    </li>
                                    <li>
                                        <strong>Pause and Save:</strong> Use the pause menu to save progress, adjust settings, or exit the game if needed.
                                    </li>
                                    <li>
                                        <strong>Get Help:</strong> If you encounter difficulties, consult the game’s help section or community forums for tips and solutions.
                                    </li>
                                </ol>
                            </div>




                            <div div className="" >
                                <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-7 gap-4">
                                    {gamesList && gamesList?.slice(15, 50)?.map((_ele, i) => {
                                        const GetSlug = _ele?.name?.en?.toLowerCase().replace(/\s+/g, "-");
                                        return (<>
                                            <GameCard key={i} id={i} slug={GetSlug} name={_ele?.name?.en} banner={_ele?.assets?.square} link={_ele?.URL} handleGameClickCount={handleGameClickCount} />
                                        </>
                                        )
                                    }

                                    )}
                                    {!isLoading && gamesList.length === 0 && <div className="col-span-12 lg:col-span-12 rounded-lg bg-white w-full h-40 flex justify-center items-center my-4">
                                        <h2 className='text-[28px] font-bold'>
                                            No games found.
                                        </h2>
                                    </div>
                                    }
                                </div>
                            </div>





                            <div className="rounded-lg bg-white  text-center mt-3 pt-1">
                                <p className='text-[8px] uppercase'>
                                    Advertisement
                                </p>
                                <div className='hidden lg:block'>
                                    <AdsComponent adSize='970x280' dataAdSlot="6934628026" />
                                </div>

                                <div className='hidden md:block lg:hidden'>
                                    <AdsComponent adSize='650x280' dataAdSlot="6934628026" />
                                </div>

                                <div className='block md:hidden'>
                                    <AdsComponent adSize={`${screenWidth}x250`} dataAdSlot="6934628026" />
                                </div>
                            </div>

                        </div>

                    </>
            }
            <AdModal isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
};


export default GamesPreview
