import React, { useEffect, useState } from 'react'
import { FaChevronUp, FaFacebookF, FaInstagram } from 'react-icons/fa'
import { MdOutlineEmail } from 'react-icons/md'
import { TfiYoutube } from 'react-icons/tfi'
import { Link } from 'react-router-dom'
const Footer = ({ configData, isHeaderFooterShown }) => {
    const [contactMail, setContactMail] = useState('');
    const [facebookLink, setFacebookLink] = useState('');
    const [instagramLink, setInstagramLink] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');

    useEffect(() => {
        if (configData.socialControl) {
            setContactMail(configData.socialControl.contactMail)
            setFacebookLink(configData.socialControl.facebookLink)
            setInstagramLink(configData.socialControl.instagramLink)
            setYoutubeLink(configData.socialControl.youtubeLink)
        }
    }, [configData])
    return (
        <>
            {isHeaderFooterShown && <div className="bg-white relative">
                <div className="page-up flex items-center justify-center" onClick={() => window.scrollTo(0, 0)}><FaChevronUp className='' /></div>
                {/* <a className="page-up flex items-center justify-center" onClick={() => window.scrollTo(0, 0)}><FaChevronUp className='' /></a> */}
                <div className="container mx-auto">
                    <div className="py-6 px-3 xl:px-0">
                        <div className="grid grid-cols-12">
                            {/* order-2 lg:order-1 lg:border-b-2 */}
                            <div className="col-span-12 lg:col-span-2 flex lg:flex-col justify-center lg:justify-between  lg:pr-4">
                                <div className="">
                                    <div className="text-xs md:text-sm mt-5 mb-3  font-normal text-center lg:hidden">Powered By</div>
                                    <div className='h-16'>
                                        <img src='../../assets/images/logo-rbg.png' width="300px" />
                                    </div>
                                </div>
                                <ul className="text-[16px] font-semibold leading-loose hidden lg:flex flex-col" >
                                    <Link to={"/privacy-policy"} className='py-0.5 ml-4'>Privacy Policy</Link >
                                    <Link to={"/terms-of-use"} className='py-0.5 ml-4'>Terms & Conditions</Link >
                                    <Link to={"/contact-us"} className='py-0.5 ml-4'>Contact Us</Link >
                                    <Link to={"/dmca"} className='py-0.5 ml-4'>DMCA</Link >
                                </ul>
                            </div>
                            <div className="col-span-12 lg:col-span-2 px-3">
                                {/* <div className="text-center lg:text-start mb-6">
                                            <h1 className="mb-1.5 text-black text-[30px] font-semibold">Contact Us</h1>
                                            <div className="text-black text-[18px] font-normal leading-10">Email : youremail@gmail.com</div>
                                        </div> */}
                                {/* flex justify-center lg:justify-start items-start  */}
                                {/* <div className="gap-[10px] my-5 lg:my-0 flex lg:flex-col justify-center lg:justify-start items-start">
                                    <Link to={`mailto:${contactMail}`} target='_blank'>
                                        <div className="flex items-center gap-3">
                                            <div className="bg-[#211E27] p-2 rounded-full text-white" >
                                                <MdOutlineEmail className='text-lg' />
                                            </div>
                                            <span className='font-bold hidden lg:block'>Email</span>
                                        </div>
                                    </Link>
                                    <Link to={facebookLink} target='_blank'>
                                        <div className="flex items-center gap-3">
                                            <div className="bg-[#211E27] p-2 rounded-full text-white" >
                                                <FaFacebookF className='text-lg' />
                                            </div>
                                            <span className='font-bold hidden lg:block'>Facebook</span>
                                        </div>
                                    </Link>
                                    <Link to={instagramLink} target='_blank'>
                                        <div className="flex items-center gap-3">
                                            <div className="bg-[#211E27] p-2 rounded-full text-white">
                                                <FaInstagram className='text-lg' />
                                            </div>
                                            <span className='font-bold hidden lg:block'>Instagram</span>
                                        </div>
                                    </Link>
                                    <Link to={youtubeLink} target='_blank'>
                                        <div className="flex items-center gap-3">
                                            <div className="bg-[#211E27] p-2 rounded-full text-white">
                                                <TfiYoutube className='text-lg' />
                                            </div>
                                            <span className='font-bold hidden lg:block'>Youtube</span>
                                        </div>
                                    </Link>
                                </div> */}
                                {/* </div> */}
                                <div className="flex lg:hidden items-center gap-10 justify-center mt-5">
                                    <Link to={"/terms-of-use"}><div className="text-black text-[14px] font-normal underline leading-none">Terms & Conditions</div></Link>
                                    <Link to={"/privacy-policy"}><div className="text-black text-[14px] font-normal underline leading-none">Privacy Policy</div></Link>

                                </div>
                                <div className="flex lg:hidden items-center gap-10 justify-center mt-5">

                                    <Link to={"/contact-us"}><div className="text-black text-[14px] font-normal underline leading-none">Contact Us</div></Link>
                                    <Link to={"/dmca"}><div className="text-black text-[14px] font-normal underline leading-none">DMCA</div></Link>
                                </div>
                            </div>
                            {/*  order-1 lg:order-2 */}
                            <div className="col-span-12 lg:col-span-8 pl-4">
                                <p className="text-black text-base font-normal hidden lg:block">
                                    <span className='block'>
                                        The largest collection of online games, we offer a seamless online platform for the best playing experience on different devices for everything. Easy to use and play for fun, relaxation, and reduce stress. Open and free.
                                    </span>
                                    <span className='block my-4'>
                                        Best place to engage and browse different games to expand your gaming skills. Let's play!
                                    </span>
                                  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2.5 border-t-2 border-t-[#F4F4F4]">
                    <div className="text-center text-xs lg:text-base font-normal capitalize leading-loose">Copyright 2024 TheGameZone.fun, All Rights Reserved.</div>
                </div>
            </div>}
        </>
    )
}

export default Footer
