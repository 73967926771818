import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AdsComponent = ({ dataAdSlot, isTagNeeded = false, adSize = '' }) => {
    const adElementId = uuidv4();
    const [isAdModalLoading, setIsAdModalLoading] = useState(true);
    const adRef = useRef(null);

    useEffect(() => {
        try {
            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
        } catch (e) {
            console.error("Adsbygoogle failed to load:", e);
        }
    }, [dataAdSlot]);

    const [width, height] = adSize.split('x').map(Number);
    const adStyle = width && height ? { width: `${width}px`, height: `${height}px` } : {};

    useEffect(() => {
        const checkAdStatus = () => {
            const adElement = adRef.current;
            const adStatus = adElement?.getAttribute('data-adsbygoogle-status');
            const adFillStatus = adElement?.getAttribute('data-ad-status');
            setIsAdModalLoading(adStatus === null || adFillStatus !== 'filled');
        };

        const observer = new MutationObserver(checkAdStatus);
        if (adRef.current) {
            observer.observe(adRef.current, { attributes: true });
        }

        return () => {
            if (adRef.current) {
                observer.disconnect();
            }
        };
    }, [dataAdSlot]);

    return (
        <div
            className={`w-[${width || '100%'}px] min-h-[${height || '200px'}px] mx-auto ${isAdModalLoading ? 'overflow-hidden' : ''
                } flex flex-col items-center relative ${isTagNeeded && !isAdModalLoading ? 'pt-1' : ''}`}
        >
            {isTagNeeded && !isAdModalLoading && (
                <p className="text-[8px] uppercase">Advertisement</p>
            )}

            {/* Skeleton Loader for loading state */}
            {isAdModalLoading && (
                <div
                    className={`bg-gray-100 animate-pulse rounded-lg ${isTagNeeded ? 'pt-1' : ''}`}
                    style={{ position: 'absolute', ...adStyle }}
                >
                    {isTagNeeded && (
                        <p className="text-[8px] uppercase text-center">Advertisement</p>
                    )}
                    <div className="bg-gray-300 h-full w-full animate-pulse"></div>
                </div>
            )}

            {/* Google AdSense Ad */}
            <div className="m-auto" style={{ position: 'relative', ...adStyle }}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    id={adElementId}
                    data-ad-client="ca-pub-8383910619750608" // replace with your ad client ID
                    data-ad-slot={dataAdSlot}
                    data-full-width-responsive="true"
                    data-ad-format="auto"
                    ref={adRef}
                ></ins>
            </div>
        </div>
    );
};

export default AdsComponent;
