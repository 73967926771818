import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
// import { gamezone_all_games, gamezone_latest_games, gamezone_trending_games } from '../../controller/actions';
import GameCard from '../../components/GameCard/GameCard';
import AdsComponent from '../../components/AdsComponent/AdsComponent';
import { NewContext } from '../../Context/NewContext';
// import Data from '../../components/gamesData.json'
import Data from '../../components/gamezop.json'
import AboutUs from '../AboutUs/AboutUs';
import axios from 'axios';


export const Games = ({ handleGameClickCount, gameAdsSettings }) => {

    const navigate = useNavigate();
    const { category } = useParams();
    const { screenWidth } = useContext(NewContext);

    // const [gamesList, setGamesList] = useState(Data?.GamesList)
    const [gamesList, setGamesList] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [pageName, setPageName] = useState("All Games")



    const getGameListFunc = async () => {
        const api_res = await axios.get("https://pub.gamezop.com/v3/games?id=9632&lang=en")
        setGamesList(api_res?.data?.games)
    }
    useEffect(() => {
        getGameListFunc();
    }, []);

    return (
        <>
            <div className="container mx-auto mt-5 mb-16">
                <div className="mx-3 lg:mx-0">
                    {/* <div className="my-6 section-title flex items-center">
                        <MdOutlineKeyboardBackspace className='text-3xl mr-6 lg:mr-9 cursor-pointer' onClick={() => navigate('/')} />
                        <h2 className='mr-2 text-base md:text-3xl leading-none font-bold relative pb-2 after:absolute after:w-[45%] after:h-[5px] after:bg-[#5AC4D1] after:bottom-[-5px] after:left-0 after:rounded-md' >{!isLoading && pageName}</h2>
                    </div> */}
                    <div className='mx-auto flex justify-center'>
                        <div className="rounded-lg bg-white w-fit h-fit text-center mt-3 pt-1">
                            <p className='text-[8px] uppercase'>
                                Advertisement
                            </p>
                            <div className='hidden xl:block'>
                                <AdsComponent adSize='1200x280' dataAdSlot="2574950302" />
                            </div>

                            <div className='hidden lg:block xl:hidden'>
                                <AdsComponent adSize='1000x280' dataAdSlot="2574950302" />
                            </div>

                            <div className='hidden md:block lg:hidden'>
                                <AdsComponent adSize='744x280' dataAdSlot="2574950302" />
                            </div>

                            <div className='block md:hidden'>
                                <AdsComponent adSize={`${screenWidth}x250`} dataAdSlot="2574950302" />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-7 gap-4 mt-3">
                        {gamesList && gamesList?.map((_ele, i) => {
                            const GetSlug = _ele?.name?.en?.toLowerCase().replace(/\s+/g, "-");
                            return (<>
                                <GameCard key={i} id={i} slug={GetSlug} name={_ele?.name?.en} banner={_ele?.assets?.square} link={_ele?.URL} handleGameClickCount={handleGameClickCount} />
                            </>
                            )
                        }

                        )}
                        {!isLoading && gamesList.length === 0 && <div className="col-span-12 lg:col-span-12 rounded-lg bg-white w-full h-40 flex justify-center items-center my-4">
                            <h2 className='text-[28px] font-bold'>
                                No games found.
                            </h2>
                        </div>
                        }
                    </div>


                    <div className="rounded-lg bg-white w-full h-fit text-center items-center mt-3 py-5">
                        <p className='text-[8px] uppercase'>
                            Advertisement
                        </p>
                        <div className='hidden xl:block'>
                            <AdsComponent adSize='1200x280' dataAdSlot="6934628026" />
                        </div>

                        <div className='hidden lg:block xl:hidden'>
                            <AdsComponent adSize='1000x280' dataAdSlot="6934628026" />
                        </div>

                        <div className='hidden md:block lg:hidden'>
                            <AdsComponent adSize='744x280' dataAdSlot="6934628026" />
                        </div>

                        <div className='block md:hidden'>
                            <AdsComponent adSize={`${screenWidth}x250`} dataAdSlot="6934628026" />
                        </div>
                    </div>
                    <AboutUs />
                </div>
            </div >
        </>
    )
}

export default Games
